import React, { useState } from 'react';

function About() {
  const [bookings, setBookings] = useState([]);
  const [error, setError] = useState(null);

  const fetchBookings = () => {
    fetch('/get_bookings/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        check_in_date: '2024-10-01',
        check_out_date: '2024-10-31'
      })
    })
      .then(response => response.json())
      .then(data => {
        setBookings(data); // Set the state with the fetched bookings
      })
      .catch(error => {
        setError('Error fetching bookings');
        console.error('Error fetching bookings:', error);
      });
  };

  return (
    <div>
      <h1>About Us</h1>
      <p>Learn more about our company.</p>
      
      {/* Button to trigger fetch */}
      <button onClick={fetchBookings}>予約を取得</button>

      {/* Display bookings */}
      <div id="bookings">
        {error && <p>{error}</p>}
        {bookings.length > 0 ? (
          bookings.map((booking, index) => (
            <div key={index}>
              <h3>予約ID: {booking.bookingId}</h3>
              <p>ゲスト名: {booking.guestName}</p>
              <p>チェックイン日: {booking.checkIn}</p>
              <p>チェックアウト日: {booking.checkOut}</p>
              <p>ステータス: {booking.status}</p>
            </div>
          ))
        ) : (
          <p>No bookings available.</p>
        )}
      </div>
    </div>
  );
}

export default About;
