import {React, useContext} from 'react';
import styles from './Home.css';  // CSSファイルをインポート
import Facilities from '../components/Facilities';
import Faq from '../components/Faq';
import Contact from '../components/Contact'
import Header from '../components/Header';
import TopImage from '../components/TopImage';
import { LanguageContext } from '../context/LanguageContext';  // 言語コンテキストをインポート


function Home() {

    // 言語コンテキストから現在の言語を取得
    const { language } = useContext(LanguageContext);

    // 言語に応じたテキストを表示
    const content = {
        jp: (
            <p>
                ゲストハウス&nbsp;巴.comは2018年に1号棟をオープンし、翌年にはOnepiece Houseをオープンしました。<br />
                現在は、6施設を運営、1施設の管理を請け負っており、それぞれの施設には独自のコンセプトがあります。<br />
                シンプルで落ち着いた雰囲気のものから、フィギュアや装飾品を展示したテーマ性の高い施設まで、快適さと個性を兼ね備えたデザインとなっており、ゲストの皆様に特別な体験を提供しています。<br />
                今では、国内外問わず多くの方にご利用いただいております。<br />
                巴.comは、函館を訪れるすべてのゲストや地域の皆様にとって、忘れられない滞在となるよう、日々サービスの向上に努めています。
            </p>
        ),
        en: (
            <p>
                Guesthouse Tomoe.com opened its first building in 2018, and the following year, Onepiece House was opened.<br />
                We currently operate six facilities and manage one other facility, each with its own unique concept.<br />
                From simple, calm designs to highly thematic facilities showcasing figures and decorations, we offer a balance of comfort and individuality, providing guests with a unique experience.<br />
                Now, we welcome guests from both Japan and abroad.<br />
                Tomoe.com is dedicated to improving its services daily to ensure an unforgettable stay for all guests and local visitors to Hakodate.
            </p>
        )
    };

    console.log('language', language)

    return (
        <>
            {/* <Header /> */}
            <TopImage />
            <main>
                <div className="explain_brief">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-12 col-md-6"> {/* Bootstrapのグリッドシステムを使用 */}
                                <div className="image">
                                    <img className="image_only" src="/images/homephoto.jpg" alt="Home" />
                                </div>
                            </div>
                            <div className="col-11 col-md-6 m-auto"> {/* col-12は小さい画面で全幅、md以上の画面では6列 */}
                                <div className="text">
                                    {/* <p>
                                        ゲストハウス&nbsp;巴.comは2018年に1号棟をオープンし、翌年にはOnepiece Houseをオープンしました。<br />
                                        現在は、6施設を運営、1施設の管理を請け負っており、それぞれの施設には独自のコンセプトがあります。<br />
                                        シンプルで落ち着いた雰囲気のものから、フィギュアや装飾品を展示したテーマ性の高い施設まで、快適さと個性を兼ね備えたデザインとなっており、ゲストの皆様に特別な体験を提供しています。<br />
                                        今では、国内外問わず多くの方にご利用いただいております。<br />
                                        巴.comは、函館を訪れるすべてのゲストや地域の皆様にとって、忘れられない滞在となるよう、日々サービスの向上に努めています。
                                    </p> */}
                                    {/* 現在の言語に応じてテキストを表示 */}
                                    {content[language]}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row  mt-5 ">
                        <div className="col-12 col-md-5 ms-5 width-75">
                            <div className="text">
                                <p>
                                    今まで、国内外問わず多くのゲストの方にご利用いただいております。<br />
                                    当施設は、さまざまな国から訪れる旅行者やビジネス客に愛されてきました。<br />
                                    函館の美しい景色や豊かな文化を楽しんでいただけるよう、ゲストの皆様に最高のおもてなしを提供できるよう日々努めています。<br />
                                    清潔で快適な客室、心温まるサービス、そして地域の魅力を活かした様々なアクティビティを通じて、ゲストの皆様の滞在が特別なものとなるようサポートしています。<br />
                                    皆様にとって思い出深い旅となるよう、スタッフ一同心を込めてお迎えいたします。<br />
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="image">
                                <img className="image_only" src="/images/DSC_0078.JPG" alt="Guesthouse" />
                            </div>
                        </div>
                    </div> */}
                </div>
                
                {/* Facilitiesコンポーネントを表示 */}
                <Facilities />
                <Faq />
                {/* <Contact /> */}


          
            </main>
        </>
    );
}

export default Home;
