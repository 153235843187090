import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function GuestInfo() {
    const location = useLocation();  // Use useLocation to get passed state
    const { room, searchConditions } = location.state || {};  // Destructure the state, with fallback to empty object

    const [guestName, setGuestName] = useState('');
    const [guestAddress, setGuestAddress] = useState('');
    const [guestPhone, setGuestPhone] = useState('');
    const [guestEmail, setGuestEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!room) {
            console.error('Room data is missing');
            return;
        }

        // Send guest info to backend
        const response = await fetch('http://127.0.0.1:8000/api/save-guest-info/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                guestName,
                guestAddress,
                guestPhone,
                guestEmail,
                room,  // Pass the room information as well
                searchConditions,
            }),
        });

        console.log('Room:', room);
        console.log('Search Conditions:', searchConditions);


        // Get response from server
        const result = await response.json();
        console.log('result', result)
        // After saving guest info, move to payment
        handlePayment(room);  // Call handlePayment after submitting guest info
    };

    // Payment function
    const handlePayment = async (room) => {
        const stripe = await stripePromise;
        console.log('room', room.facility);  // Check if 'room' has data

        const response = await fetch('http://127.0.0.1:8000/api/create-checkout-session/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                roomName: room.facility,
                price: 10000,  // Stripe requires amounts in smallest currency unit
            }),
        });

        const session = await response.json();

        // Redirect to Stripe checkout
        const { error } = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (error) {
            console.error('Error:', error);
        }

    };

    return (
        <div>
            <h2>宿泊者情報入力</h2>
            {room && (
                <div>
                    <h3>宿泊施設: {room.facility}</h3>
                    <p>料金: ¥{room.price}</p>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>代表者氏名:</label>
                    <input
                        type="text"
                        value={guestName}
                        onChange={(e) => setGuestName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>代表者住所:</label>
                    <input
                        type="text"
                        value={guestAddress}
                        onChange={(e) => setGuestAddress(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>代表者の電話番号:</label>
                    <input
                        type="tel"
                        value={guestPhone}
                        onChange={(e) => setGuestPhone(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>代表者のメールアドレス:</label>
                    <input
                        type="email"
                        value={guestEmail}
                        onChange={(e) => setGuestEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">予約を確定して支払いに進む</button>
            </form>
        </div>
    );
}

export default GuestInfo;
