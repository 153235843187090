import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ReservationForm.module.css';
import { ClipLoader } from 'react-spinners'; // Import spinner component

function ReservationForm() {
    const [checkInDate, setCheckInDate] = useState({
        year: '',
        month: '',
        day: '',
    });

    const [checkOutDate, setCheckOutDate] = useState({
        year: '',
        month: '',
        day: '',
    });

    const [guests, setGuests] = useState({
        adults: 0,
        children: 0,
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);  // State to manage loading spinner
    const navigate = useNavigate();

    const years = Array.from({ length: 2 }, (_, i) => 2024 + i);
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const days = Array.from({ length: 31 }, (_, i) => i + 1);

    const handleCheckInChange = (e) => {
        const { name, value } = e.target;
        setCheckInDate((prevDate) => ({ ...prevDate, [name]: value }));
    };

    const handleCheckOutChange = (e) => {
        const { name, value } = e.target;
        setCheckOutDate((prevDate) => ({ ...prevDate, [name]: value }));
    };

    const handleGuestsChange = (e) => {
        const { name, value } = e.target;
        setGuests((prevGuests) => ({
            ...prevGuests,
            [name]: parseInt(value, 10),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formatDate = (date) => `${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;

        const formattedCheckInDate = formatDate(checkInDate);
        const formattedCheckOutDate = formatDate(checkOutDate);

        const currentDate = new Date();
        const checkIn = new Date(checkInDate.year, checkInDate.month - 1, checkInDate.day);
        const checkOut = new Date(checkOutDate.year, checkOutDate.month - 1, checkOutDate.day);

        if (checkIn.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
            setErrorMessage('チェックイン日は現在の日付以降を指定してください。');
            return;
        }

        if (checkIn >= checkOut) {
            setErrorMessage('チェックイン日はチェックアウト日より前に設定してください。');
            return;
        }

        setErrorMessage('');
        setLoading(true);  // Show loading spinner

        // Send the request to the backend
        fetch('http://127.0.0.1:8000/api/availability/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                check_in_date: formattedCheckInDate,
                check_out_date: formattedCheckOutDate,
                number_of_adults: guests.adults,
                number_of_children: guests.children,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(err => {
                        console.error('Error:', err);
                        setLoading(false);  // Hide loading spinner
                        throw new Error('Something went wrong');
                    });
                }
                return response.json();
            })
            // .then(data => {
            //     console.log('Success:', data);
            //     setLoading(false);  // Hide loading spinner
            //     navigate('/search-results', { state: { availableRooms: data.available_rooms } });
            // })
            .then(data => {
                // 検索結果と検索条件を一緒にSearchResultsに渡す
                navigate('/search-results', {
                    state: {
                        availableRooms: data.available_rooms,  // 検索結果
                        searchConditions: {  // 検索条件
                            check_in_date: formattedCheckInDate,
                            check_out_date: formattedCheckOutDate,
                            number_of_adults: guests.adults,
                            number_of_children: guests.children,
                        },
                    },
                });
            })

            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);  // Hide loading spinner
            });
    };

    return (
        <div className={styles.container} style={{marginTop: '100px',}}>
            <h3>予約フォーム</h3>

            {/* Check-in Date */}
            <div className={styles.formGroup}>
                <label>チェックイン日:</label>
                <select name="year" value={checkInDate.year} onChange={handleCheckInChange}>
                    <option value="">年</option>
                    {years.map((year) => (
                        <option key={year} value={year}>{year}年</option>
                    ))}
                </select>
                <select name="month" value={checkInDate.month} onChange={handleCheckInChange}>
                    <option value="">月</option>
                    {months.map((month) => (
                        <option key={month} value={month}>{month}月</option>
                    ))}
                </select>
                <select name="day" value={checkInDate.day} onChange={handleCheckInChange}>
                    <option value="">日</option>
                    {days.map((day) => (
                        <option key={day} value={day}>{day}日</option>
                    ))}
                </select>
            </div>

            {/* Check-out Date */}
            <div className={styles.formGroup}>
                <label>チェックアウト日:</label>
                <select name="year" value={checkOutDate.year} onChange={handleCheckOutChange}>
                    <option value="">年</option>
                    {years.map((year) => (
                        <option key={year} value={year}>{year}年</option>
                    ))}
                </select>
                <select name="month" value={checkOutDate.month} onChange={handleCheckOutChange}>
                    <option value="">月</option>
                    {months.map((month) => (
                        <option key={month} value={month}>{month}月</option>
                    ))}
                </select>
                <select name="day" value={checkOutDate.day} onChange={handleCheckOutChange}>
                    <option value="">日</option>
                    {days.map((day) => (
                        <option key={day} value={day}>{day}日</option>
                    ))}
                </select>
            </div>

            {/* Guests Information */}
            <div className={styles.formGroup}>
                <label>大人の人数:</label>
                <select name="adults" value={guests.adults} onChange={handleGuestsChange}>
                    {Array.from({ length: 10 }, (_, i) => i).map((num) => (
                        <option key={num} value={num}>{num} 人</option>
                    ))}
                </select>

                <label style={{marginTop: '20px',}}>子供の人数:</label>
                <select name="children" value={guests.children} onChange={handleGuestsChange}>
                    {Array.from({ length: 10 }, (_, i) => i).map((num) => (
                        <option key={num} value={num}>{num} 人</option>
                    ))}
                </select>
            </div>

            {/* Error Message */}
            {errorMessage && <p className={styles.error}>{errorMessage}</p>}

            {/* Loading Spinner */}
            {loading ? (
                <div className={styles.spinnerContainer}>
                    <ClipLoader color="#000" loading={loading} size={50} />
                </div>
            ) : (
                <button onClick={handleSubmit}>検索</button>
            )}
        </div>
    );
}

export default ReservationForm;
