import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function SearchResults() {
    const location = useLocation();
    const { availableRooms, searchConditions } = location.state || { availableRooms: [], searchConditions: {} }; 
    const navigate = useNavigate();

    const handleReservationClick = (room) => {
        // クリック時に宿泊者情報入力ページに遷移
        console.log('test', room.facility)
        navigate('/guest-info', { state: { room, searchConditions } });
    };

    return (
        <div>
            <h2>検索結果</h2>

            {/* 検索条件の表示 */}
            {searchConditions && (
                <div>
                    <h3>検索条件</h3>
                    <p>チェックイン日: {searchConditions.check_in_date}</p>
                    <p>チェックアウト日: {searchConditions.check_out_date}</p>
                    <p>大人の人数: {searchConditions.number_of_adults}</p>
                    <p>子供の人数: {searchConditions.number_of_children}</p>
                </div>
            )}

            {/* 空き部屋のリスト */}
            {availableRooms && availableRooms.length > 0 ? (
                availableRooms.map((room, index) => (
                    <div key={index}>
                        <h3>{room.facility}</h3>
                        <p>料金: ¥{room.price}</p>
                        <button onClick={() => handleReservationClick(room)}>宿泊者情報入力</button>
                    </div>
                ))
            ) : (
                <p>空き施設はありません。</p>
            )}
        </div>
    );
}

export default SearchResults;
