import React from 'react';

function PaymentSuccess() {
    return (
        <div>
            <h2>支払いが成功しました！</h2>
            <p>予約が確定されました。ご利用ありがとうございます。</p>
        </div>
    );
}

export default PaymentSuccess;
