import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.css';  // CSS Modulesのインポート


function TopImage() {

    return (
        <>
            <div className={styles['top-image'] + ' container-fluid'}></div>
        </>
    );
}
export default TopImage;
