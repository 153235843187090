import React from 'react';
import styles from './Contact.module.css'; // CSSモジュールを使う場合

function Contact() {
    return (
        <div className="styles.container m-auto" style={{ paddingBottom: '100px' }}>
            <div className={styles.contact}>
                <div className="row">
                    <div className={styles.contact__content}>
                        <div className={styles.contact__title}>
                            <p className="p-3 m-auto border border-dark">お問合せ先</p>
                        </div>
                        <div className={styles.contact__icon}>
                            <div className={styles.mail}>
                                <img src="images/icons/mail.svg" alt="メール" />
                                <span className='ms-3'>hakodateshino0901@gmail.com</span>
                            </div>
                            <div className={styles.tel}>
                                <img src="images/icons/phone-call.svg" alt="電話" />
                                <span className='ms-3'>080-9322-4522</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
