import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from './Header.module.css';  // CSS Modulesのインポート


function Header() {

    const navigate = useNavigate();

    const handleClick = () => {
        // "/booking" ページに遷移
        navigate('/reservation');
    };

    return (
        <>
            <header id={styles.header} className="container-fluid">
                <Link to="/" className={styles.logo}>
                    <img src="../../images/tomoe_logo.png" alt="Tomoe Logo" />
                </Link>
            </header>
            {/* <div className={styles['booking']}>
                <button className={styles.button} onClick={handleClick}>
                    Booking
                </button>
            </div> */}

            {/* <div className={styles['top-image'] + ' container-fluid'}></div> */}
        </>
    );
}
export default Header;
