import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { facilityDetails } from '../components/FacilityData';
import Masonry from 'react-masonry-css';
import styles from './FacilityDetail.module.css';

function FacilityDetail() {
    const { id } = useParams(); // URLから施設のIDを取得
    const facility = facilityDetails[id]; // データから該当する施設を取得

    // 最初に表示する画像の数
    const [showAllImages, setShowAllImages] = useState(false);

    // ページ遷移時にスクロール位置をリセット
    useEffect(() => {
        window.scrollTo(0, 0);  // ページトップにスクロール
    }, []);  // 初回レンダリング時のみ実行

    if (!facility) {
        return <div>Facility not found</div>; // IDが不正な場合のエラーハンドリング
    }

    // 表示する画像のリストを作成
    const imagesToShow = showAllImages ? facility.images : facility.images.slice(0, 3);

    return (
        <div className={`${styles.facilityDetail} ${styles['custom-row-width']}`}>
            <div className={styles.facilityContent}>
                {/* 施設名 */}
                <h1>{facility.title}</h1>

                {/* 住所表示 */}
                <p><strong>住所:</strong> {facility.address}</p>

                {/* 施設説明文 */}
                <p>{facility.description}</p>

                {/* アメニティ、家電を表示 */}
                <div className={styles.amenitiesAndAppliances}>
                    <div>
                        <h3>アメニティ</h3>
                        <ul>
                            {facility.amenities.map((amenity, idx) => (
                                <li key={idx}>{amenity}</li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <h3>家電・設備</h3>
                        <ul>
                            {facility.appliances.map((appliance, idx) => (
                                <li key={idx}>{appliance}</li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <iframe
                            title="Google Maps"
                            src={facility.googleMapEmbedLink}
                            width="400"
                            height="300"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>



            {/* 画像ギャラリーを表示 */}
            <Masonry
                breakpointCols={{ default: 3, 700: 2, 500: 1 }}
                className={styles["my-masonry-grid"]}
                columnClassName={styles["my-masonry-grid_column"]}
            >
                {imagesToShow.map((image, idx) => (
                    <img key={idx} src={image} alt={`${facility.title} image ${idx + 1}`} className={styles.image} />
                ))}
            </Masonry>

            {/* 「もっと見る」ボタンを表示 */}
            {!showAllImages && facility.images.length > 5 && (
                // <button onClick={() => setShowAllImages(true)} className={styles.showMoreButton}>
                // もっと見る
                // </button>
                <div className={styles.buttonContainer}>
                    <button onClick={() => setShowAllImages(true)} className={styles.showMoreButton}>
                        もっと見る
                    </button>
                </div>
            )}
        </div>
    );
}

export default FacilityDetail;
