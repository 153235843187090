export const facilityDetails = {

  // 1号棟
  87452: {
    title: "Guest House 巴.com",
    description: "函館駅から徒歩10分でアクセスも抜群。最大11名まで宿泊可能な広々とした空間は、グループや家族旅行に最適です。オーナーの趣味が光るコレクションや、ユニークなテーマでデザインされたベッドルーム、個性豊かな家具の配置など、年齢問わず訪れるすべての方が楽しめる工夫がされています。お子様から大人まで、どなたでも落ち着いた時間をお過ごしいただけます。1階には、人気アニメ『ワンピース』をはじめとした数々のフィギュアや、懐かしの映画ポスターが展示され、ノスタルジックで遊び心に満ちた雰囲気が広がっています。",
    address: "北海道函館市松風町1-2",
    googleMapEmbedLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2975.7185149281036!2d140.73036747612332!3d41.76972637125394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9ef30f57056717%3A0xdf442a3228a756ff!2z44Ky44K544OI44OP44Km44K55be0LmNvbQ!5e0!3m2!1sja!2sjp!4v1729419132666!5m2!1sja!2sjp",
    amenities: [
      "Wi-Fi",
      "無料駐車場",
      "バスタオル",
      "フェイスタオル",
      "シャンプー",
      "コンディショナー",
      "ボディソープ",
    ],
    appliances: [
      "冷蔵庫",
      "電子レンジ",
      "電子ケトル",
      "トースター",
      "電気コンロ",
      "コーヒーメーカー",
      "洗濯機",
      "エアコン",
      "こたつ",
      "テレビ"
    ],

    images: [
      "/images/build1/IMG_3612.JPG",
      "/images/build1/facade1.jpg",
      "/images/build1/DSC_1090.jpg",
      "/images/build1/IMG_3611.JPG",
      "/images/build1/DSC_1098.jpg",
      "/images/build1/DSC_1069.jpg",
      "/images/build1/DSC_1072.jpg",
      "/images/build1/DSC_1073.jpg",
      "/images/build1/DSC_1077.jpg",
      "/images/build1/DSC_1076.jpg",
      "/images/build1/DSC_1083.jpg",
      "/images/build1/DSC_1084.jpg",
      "/images/build1/DSC_1085.jpg",
      "/images/build1/DSC_1089.jpg",
      "/images/build1/DSC_1088.jpg",
      "/images/build1/DSC_1082.jpg",
      "/images/build1/DSC_1079.jpg",
      "/images/build1/DSC_1092.jpg",
      "/images/build1/DSC_1093.jpg",
      "/images/build1/DSC_1097.jpg",
      "/images/build1/DSC_1095.jpg",
      "/images/build1/furisode.jpg",
      "/images/build1/furisode2.jpg",
      "/images/build1/vader.jpg",
      "/images/build1/IMG_3751.JPG",
      "/images/build1/IMG_3749.JPG",
      "/images/build1/IMG_3750.JPG",
      "/images/build1/cady.jpg",
      "/images/build1/toilet3.jpeg",
      "/images/build1/toilet_washBasin.jpg",
      "/images/build1/floormap.png",
      "/images/build1/IMG_0687.jpg",
      "/images/build1/IMG_0688.jpg",
      "/images/build1/DSC_0078.jpg",
      "/images/build1/DSC_0080.jpg",
    ]
  },


  
  // 2号棟
  121308: {
    title: "巴.com2 Onepiece House",
    description: "施設名の通り、館内は『ワンピース』一色！人気キャラクターのフィギュアやポスター、そして全巻揃った漫画が揃い、ファンにはたまらない空間です。最大6名まで宿泊可能なため、家族や友人同士でワンピースの世界観を楽しみながらゆったりとお過ごしいただけます。<br/>ワンピースのキャラクターに囲まれた特別な時間を、ぜひここでお楽しみください。",
    address: "北海道函館市松風町1-21",
    googleMapEmbedLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2975.7205930587083!2d140.73055277612312!3d41.76968157125389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9ef37540060de7%3A0xc63249d245f14ec7!2z44CSMDQwLTAwMzUg5YyX5rW36YGT5Ye96aSo5biC5p2-6aKo55S677yR4oiS77yS77yR!5e0!3m2!1sja!2sjp!4v1729419863589!5m2!1sja!2sjp",
    amenities: [
      "Wi-Fi",
      "無料駐車場",
      "バスタオル",
      "フェイスタオル",
      "シャンプー",
      "コンディショナー",
      "ボディソープ",
    ],
    appliances: [
      "冷蔵庫",
      "電子レンジ",
      "電子ケトル",
      "トースター",
      "電気コンロ",
      "エアコン",
      "こたつ",
      "テレビ"
    ],
    images: [
      "/images/build2/buidl2_facade.jpeg",
      "/images/build2/DSC_1100.jpg",
      "/images/build2/DSC_1103.jpg",
      "/images/build2/living.jpg",
      "/images/build2/DSC_1101.jpg",
      "/images/build2/figure.jpg",
      "/images/build2/DSC_1104.jpg",
      "/images/build2/DSC_1107.jpg",
      "/images/build2/DSC_1108.jpg",
      "/images/build2/DSC_1109.jpg",
      "/images/build2/DSC_1110.jpg",
      "/images/build2/DSC_1111.jpg",
      "/images/build2/DSC_1114.jpg",
      "/images/build2/DSC_1113.jpg",
      "/images/build2/kitchen.jpg",
      "/images/build2/kitchen.png",
      "/images/build2/toilet_washBasin.jpg",
      
    ]
  },
  
  // 3号棟
  168898: {
    title: "巴.com3 Music Stay",
    description: "この施設は、昭和時代を象徴する音楽が彩る、懐かしさと趣ある空間です。館内には当時の名曲が収められたCDが飾られ、訪れるたびに昭和の雰囲気に浸ることができます。最大6名まで宿泊可能で、友人や家族とともにレトロなムードを楽しみながら、ゆっくりとした時間をお過ごしください。ノスタルジックな昭和の空気とモダンな快適さが融合したこの施設で、特別なひとときをお楽しみいただけます。",
    address: "北海道函館市東雲町19-2",
    googleMapEmbedLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2975.7205930587083!2d140.73055277612312!3d41.76968157125389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9ef3754968cbc1%3A0xc06e64415c612c31!2z44CSMDQwLTAwMzYg5YyX5rW36YGT5Ye96aSo5biC5p2x6Zuy55S677yR77yZ4oiS77yS!5e0!3m2!1sja!2sjp!4v1729419915426!5m2!1sja!2sjp",
    amenities: [
      "Wi-Fi",
      "無料駐車場",
      "バスタオル",
      "フェイスタオル",
      "シャンプー",
      "コンディショナー",
      "ボディソープ",
    ],
    appliances: [
      "冷蔵庫",
      "電子レンジ",
      "電子ケトル",
      "トースター",
      "コンロ",
      "洗濯機",
      "乾燥機",
      "エアコン",
      "ストーブ",
      "テレビ"
    ],
    images: [
      "/images/build3/DSC_1140.jpg",
      "/images/build3/DSC_1141.jpg",
      "/images/build3/DSC_1131.jpg",
      "/images/build3/DSC_1132.jpg",
      "/images/build3/DSC_1133.jpg",
      "/images/build3/DSC_1134.jpg",
      "/images/build3/DSC_1136.jpg",
      "/images/build3/DSC_1138.jpg",
      "/images/build3/DSC_1129.jpg",
      "/images/build3/DSC_1130.jpg",
      "/images/build3/DSC_1144.jpg",
      "/images/build3/DSC_1145.jpg",
      "/images/build3/DSC_1146.jpg",
      "/images/build3/DSC_1147.jpg",
      "/images/build3/bathroom.jpg",
      "/images/build3/toilet.png",
      "/images/build3/IMG_3328.jpeg",
      "/images/build3/IMG_3522.JPG",

    ]
  },
  
  // 4号棟
  189698: {
    title: "巴.com4 Motomachi",
    description: "この施設は、函館山ロープウェイまで徒歩5分という好立地にあります。また、金森赤レンガ倉庫や函館西部地区の観光スポットにも近く、歴史的な街並みを存分に楽しめる場所です。さらに、路面電車の乗り場も徒歩圏内にあり、五稜郭などの離れた名所にもスムーズにアクセスできます。観光にも便利なこの施設で、函館の魅力を余すことなく堪能してください。",
    address: "北海道函館市元町18-19",
    googleMapEmbedLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2976.083514372131!2d140.71235537612296!3d41.761857171254746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9ef300cc62c799%3A0xd8ad42872caf2b93!2z44CSMDQwLTAwNTQg5YyX5rW36YGT5Ye96aSo5biC5YWD55S677yR77yY4oiS77yR77yZ!5e0!3m2!1sja!2sjp!4v1729419955010!5m2!1sja!2sjp",
    amenities: [
      "Wi-Fi",
      "無料駐車場",
      "バスタオル",
      "フェイスタオル",
      "シャンプー",
      "コンディショナー",
      "ボディソープ",
    ],
    appliances: [
      "冷蔵庫",
      "電子レンジ",
      "電子ケトル",
      "トースター",
      "電気コンロ",
      "洗濯機",
      "エアコン",
      "テレビ"
    ],
    images: [
      "/images/build4/facade.png",
      "/images/build4/bedroom.png",
      "/images/build4/entrance.png",
      "/images/build4/living.png",
      "/images/build4/washing.png",
      "/images/build4/bathroom.png",
      "/images/build4/toilet.png",

    ]
  },
  
  // 5号棟
  236983: {
    title: "巴.com5 Cafe&Stay",
    description: "この施設は、最大3名まで宿泊可能な少人数向けの施設です。リビングとベッドが一つのワンルームに収められ、広々とした開放的な空間が広がっています。ゆったりとくつろぎたい方におすすめです。また、1階にはガパオライスの専門店があり、ランチタイムには本格的なガパオライスをお楽しみいただけます。観光や滞在中に、ぜひ一度お立ち寄りください。",
    address: "北海道函館市松風町1-21",
    googleMapEmbedLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2975.7205930587083!2d140.73055277612312!3d41.76968157125389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9ef37540060de7%3A0xc63249d245f14ec7!2z44CSMDQwLTAwMzUg5YyX5rW36YGT5Ye96aSo5biC5p2-6aKo55S677yR4oiS77yS77yR!5e0!3m2!1sja!2sjp!4v1729419863589!5m2!1sja!2sjp",
    amenities: [
      "Wi-Fi",
      "無料駐車場",
      "バスタオル",
      "フェイスタオル",
      "シャンプー",
      "コンディショナー",
      "ボディソープ",
    ],
    appliances: [
      "冷蔵庫",
      "電子レンジ",
      "電子ケトル",
      "トースター",
      "電気コンロ",
      "エアコン",
      "ストーブ",
      "テレビ"
    ],
    images: [
      "/images/build5/DSC_3390.jpg",
      "/images/build5/DSC_3396.jpg",
      "/images/build5/DSC_1115.jpg",
      "/images/build5/DSC_1116.jpg",
      "/images/build5/DSC_1117.jpg",
      "/images/build5/DSC_1118.jpg",
      "/images/build5/DSC_1119.jpg",
      "/images/build5/DSC_1120.jpg",
      "/images/build5/DSC_1121.jpg",
      "/images/build5/DSC_1122.jpg",
      "/images/build5/DSC_1123.jpg",
      "/images/build5/DSC_1124.jpg",
      "/images/build5/DSC_1125.jpg",
      "/images/build5/DSC_1126.jpg",
      "/images/build5/DSC_1127.jpg",
      "/images/build5/DSC_1128.jpg",

    ]
  },
  
  // 6号棟
  227305: {
    title: "巴.com6 PremiumStay",
    description: "この施設は、金森赤レンガ倉庫まで徒歩5分という非常に便利な立地にあります。函館西部地区の歴史的な雰囲気を感じながら、ショッピングや観光を楽しむことができます。さらに、路面電車の乗り場も近く、五稜郭などの名所への移動にも便利です。観光拠点として最適なこの施設で、函館を満喫してください。",
    address: "北海道函館市豊川町9-14",
    googleMapEmbedLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2975.887217957545!2d140.7174390761231!3d41.76608937125417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f9ef3a7f31f3ecd%3A0x31dd44831fcf0ffc!2z44CSMDQwLTAwNjUg5YyX5rW36YGT5Ye96aSo5biC6LGK5bed55S677yZ4oiS77yR77yU!5e0!3m2!1sja!2sjp!4v1729419754803!5m2!1sja!2sjp",
    amenities: [
      "Wi-Fi",
      "無料駐車場",
      "バスタオル",
      "フェイスタオル",
      "シャンプー",
      "コンディショナー",
      "ボディソープ",
    ],
    appliances: [
      "冷蔵庫",
      "電子レンジ",
      "電子ケトル",
      "トースター",
      "電気コンロ",
      "洗濯機",
      "乾燥機",
      "エアコン",
      "テレビ"
    ],

    images: [
      "/images/build6/DSC_3385.jpg",
      "/images/build6/DSC_3388.jpg",
      "/images/build6/DSC_1173.jpg",
      "/images/build6/DSC_1174.jpg",
      "/images/build6/DSC_1178.jpg",
      "/images/build6/DSC_1171.jpg",
      "/images/build6/DSC_1176.jpg",
      "/images/build6/DSC_1168.jpg",
      "/images/build6/DSC_1169.jpg",
      "/images/build6/DSC_1148.jpg",
      "/images/build6/DSC_1150.jpg",
      "/images/build6/DSC_1151.jpg",
      "/images/build6/DSC_1152.jpg",
      "/images/build6/DSC_1153.jpg",
      "/images/build6/DSC_1154.jpg",
      "/images/build6/DSC_1155.jpg",
      "/images/build6/DSC_1156.jpg",
      "/images/build6/DSC_1157.jpg",
      "/images/build6/DSC_1158.jpg",
      "/images/build6/DSC_1161.jpg",
      "/images/build6/DSC_1162.jpg",
      "/images/build6/DSC_1163.jpg",
      "/images/build6/DSC_1165.jpg",
      "/images/build6/DSC_1166.jpg",
    ]
  },
  
};
