import React from 'react';
import faqStyles from './Faq.module.css';
import contactStyles from './Contact.module.css';


function Faq() {
    return (
        <div className="container justify-content-center">
            <div className={faqStyles.faq} style={{ padding: '100px 0' }}>
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-center fs-1">FAQ.</h3>
                    </div>
                </div>
                <div className="row faq__detail">
                    <div className="col-10 col-md-10 col-lg-7 mt-5 m-auto d-flex justify-content-center">
                        <table className="table border-dark">
                            <tbody>
                                <tr>
                                    <td className="pt-4">
                                        <p className={faqStyles.question}>Q. チェックインとチェックアウトは何時ですか。</p>
                                        <p className={faqStyles.answer}>A. チェックイン15時より、チェックアウト11時、アーリーチェックインはご相談下さい。</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pt-4">
                                        <p className={faqStyles.question}>Q. 予約なしで当日宿泊は可能ですか？</p>
                                        <p className={faqStyles.answer}>A. 空きがあれば、当日宿泊も可能です。事前にお電話でご確認ください。</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pt-4">
                                        <p className={faqStyles.question}>Q. 喫煙は可能ですか？</p>
                                        <p className={faqStyles.answer}>A. 全館禁煙です。1号棟の玄関前のみ灰皿を置いておりますのでご利用ください。</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pt-4">
                                        <p className={faqStyles.question}>Q. アメニティは何がありますか？</p>
                                        <p className={faqStyles.answer}>A. 歯ブラシ、タオル、シャンプー、リンス、ボディソープをご用意しておりますが、パジャマはございません。</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pt-4">
                                        <p className={faqStyles.question}>Q. キャンセルポリシーはどうなっていますか？</p>
                                        <p className={faqStyles.answer}>A. チェックインの2日前までのキャンセルは無料です。前日キャンセルは50%、当日キャンセルは100%いただきます。</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pt-4">
                                        <p className={faqStyles.question}>Q. 支払い方法は？</p>
                                        <p className={faqStyles.answer}>A. クレジットカード、現金、QR決済に対応可能です。</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pt-4">
                                        <p className={faqStyles.question}>Q. バリアフリー対応していますか？</p>
                                        <p className={faqStyles.answer}>A. バリアフリーには対応しておりません。階段はありませんので、ご了承ください。</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="styles.container m-auto" style={{ paddingBottom: '100px' }}>
                        <div className={contactStyles.contact}>
                            <div className="row">
                                <div className={contactStyles.contact__content}>
                                    <div className={contactStyles.contact__title}>
                                        <p className="p-3 m-auto border border-dark">お問合せ先</p>
                                    </div>
                                    <div className={contactStyles.contact__icon}>
                                        <div className={contactStyles.mail}>
                                            <img src="images/icons/mail.svg" alt="メール" />
                                            <span className='ms-3'>hakodateshino0901@gmail.com</span>
                                        </div>
                                        <div className={contactStyles.tel}>
                                            <img src="images/icons/phone-call.svg" alt="電話" />
                                            <span className='ms-3'>080-9322-4522</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >



    );
}

export default Faq;
