import React from 'react';
import ReservationForm from '../components/ReservationForm';

function Reservation() {
  return (

    <ReservationForm />
  );
}

export default Reservation;
