import React from 'react';
import { Link} from 'react-router-dom';

import styles from './Facilities.module.css';  // CSS Modulesを使用
import { Carousel, Button } from 'react-bootstrap'; // react-bootstrapのCarouselを使用
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import 'bootstrap/dist/css/bootstrap.min.css'; // 必要な場合にインポート

// カードごとに異なるデータを用意する
const cardsData = [
    {
        id: 87452,
        title: "Guest House 巴.com",
        capacity: "11名/貸切、6名/部屋貸し",
        price: "20,000円",
        location: "函館駅から徒歩10分",
        imgSrc: "/images/build1/IMG_3612.JPG"
    },
    {
        id: 121308,
        title: "巴.com2 Onepiece House",
        capacity: "6名",
        price: "20,000円",
        location: "函館駅から徒歩10分",
        imgSrc: "/images/build2/buidl2_facade.jpeg"
    },
    {
        id: 168898,
        title: "巴.com3 Music Stay",
        capacity: "6名",
        price: "20,000円",
        location: "函館駅から徒歩10分",
        imgSrc: "/images/build3/DSC_1141.jpg"
    },
    {
        id: 189698,
        title: "巴.com4 Motomachi",
        capacity: "6名",
        price: "20,000円",
        location: "函館山ロープウェイまで徒歩3分",
        imgSrc: "/images/build4/living.png"
    },
    {
        id: 236983,
        title: "巴.com5 Cafe&Stay",
        capacity: "3名",
        price: "10,000円",
        location: "函館駅から徒歩10分",
        imgSrc: "/images/build5/DSC_1116.jpg"
    },
    {
        id: 227305,
        title: "巴.com6 PremiumStay",
        capacity: "6名",
        price: "20,000円",
        location: "十字街駅から徒歩5分",
        imgSrc: "/images/build6/DSC_3386.jpg"
    }
];

function Facilities() {
    return (
        <div className={`facilities container-fluid `}>
            <div className="title">
                <p class="text-center fs-1">Facilities</p>
            </div>

            <Row className={`g-4 d-flex justify-content-center m-auto ${styles["custom-row-width"]}`}>
                {cardsData.map((card, idx) => (
                    <Col key={idx} xs={12} sm={6} md={6} lg={4}>
                        <Card>
                            <Card.Img variant="top" src={card.imgSrc} />
                            <Card.Body>
                                <Card.Title>{card.title}</Card.Title>
                                <Card.Text className='mb-0'>定員:&nbsp;{card.capacity}</Card.Text>
                                <Card.Text className='mb-0'>{card.location}</Card.Text>
                                <Card.Text className='mb-0'>{card.price}~/1泊</Card.Text>
                                <Link to={`/facility/${card.id}`}>
                                    <Button variant="primary">詳しく見る</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            
        </div>
    );
}

export default Facilities;
