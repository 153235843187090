import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Reservation from './pages/Reservation';
import SearchResults from './pages/SearchResults';
import GuestInfo from './pages/GuestInfo';
import FacilityDetail from './pages/FacilityDetail'; // 詳細ページコンポーネント


import Navbar from './components/Navbar';  // ナビゲーションバーのインポート
import Header from './components/Header';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancel from './components/PaymentCancel';


function App() {




  return (
    <Router>
      <div>
        <Header />
        {/* <Navbar />  ナビゲーションバーを追加 */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/reservation" element={<Header />} />
          <Route path="/search-results" element={<SearchResults />} />
          <Route path="/guest-info" element={<GuestInfo />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/cancel" element={<PaymentCancel />} />
          <Route path="/facility/:id" element={<FacilityDetail />} /> {/* 遷移先のルート */}
          {/* <Route path="/test" element={<Test />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
