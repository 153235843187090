import React from 'react';

function PaymentCancel() {
    return (
        <div>
            <h2>支払いがキャンセルされました。</h2>
            <p>もう一度やり直してください。</p>
        </div>
    );
}

export default PaymentCancel;
